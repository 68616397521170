import getUserLocale from 'get-user-locale';

class LanguageDetector {
  private langKey: string;
  private defaultLang: string;

  constructor(defaultLang: string = 'en', key: string = 'lang') {
    this.langKey = key;
    this.defaultLang = defaultLang;
    console.log(`[LanguageDetector] v1.0.0`);
  }

  public Detect(): string | null {
    const langFromUrl = this.checkUrl();
    if (langFromUrl) {
      console.log(`[LanguageDetector] found ${langFromUrl} from URL`);
      return langFromUrl;
    }

    const langFromStorage = this.checkLocalStorage();
    if (langFromStorage) {
      console.log(`[LanguageDetector] found ${langFromStorage} from storage`);
      return langFromStorage;
    }

    const langFromNavigator = this.checkNavigator();
    if (langFromNavigator) {
      console.log(
        `[LanguageDetector] found ${langFromNavigator} from navigator locale`
      );
      return langFromNavigator;
    }

    console.log(
      `[LanguageDetector] using fallback default ${this.defaultLang}`
    );
    return this.defaultLang;
  }

  private checkNavigator(): string | null {
    const locale = getUserLocale() ?? "";
    return this.clear(locale);
  }

  private checkUrl(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    let lang = urlParams.get(this.langKey) ?? "";
    return this.clear(lang);
  }

  private checkLocalStorage(): string | null {
    const localStorage = window.localStorage;
    let lang = localStorage.getItem(this.langKey) ?? "";
    return this.clear(lang);
  }

  private clear(language: string) {
    language = language.replace('\\', '');
    language = language.replace('"', '');
    language = language.trim();
    if (language.length>2 ) {
      language = language.substring(0, 2);
    }
    return language;
  }
}

export default LanguageDetector;
