/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components'

const Button = styled.button`
  align-items: center;
  background: ${(props) => props.theme.ctaButton.background};
  border: ${(props) => props.theme.ctaButton.border};
  border-radius: ${(props) => props.theme.ctaButton.borderRadius};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.ctaButton.boxShadow};
  color: ${(props) => props.theme.ctaButton.color};
  cursor: pointer;
  display: flex;
  min-height: ${(props) => props.theme.ctaButton.minHeight};
  font: ${(props) => props.theme.ctaButton.font};
  justify-content: space-evenly;
  padding: ${(props) => props.theme.ctaButton.padding};
  width: ${(props) => props.theme.ctaButton.width};

  &:focus {
    border: 0;
    border-width: 0;
    outline: none;
  }

  &:active {
    border: 0;
    border-width: 0;
    outline: none;
  }

  &:visited {
    border: 0;
    border-width: 0;
    outline: none;
  }

  & img.fpt-cta-button__logo {
    filter: ${(props) => props.theme.ctaButton.filter};
    height: ${(props) => props.theme.ctaButton.iconHeight};
    margin: 0 20px 0 0;
  }

  & img.fpt-cta-button__arrow {
    display: ${(props) => props.theme.ctaButton.arrowDisplay};
  }

  & > img.fpt-cta-button__spinner {
    display: none;
  }

  &.fpt-loading > img.fpt-cta-button__spinner {
    display: inline-block;
  }

  &.fpt-loading .fpt-cta-button__logo {
    display: none;
  }

  &.fpt-loading .fpt-cta-button__label {
    display: none;
  }

  &.fpt-loading .fpt-cta-button__arrow {
    display: none;
  }

  &.fpt-btn-link {
    background: ${(props) => props.theme.ctaLink.background};
    color: ${(props) => props.theme.ctaLink.color};
    padding: ${(props) => props.theme.ctaLink.padding};
    margin: ${(props) => props.theme.ctaLink.paddingmargin};
    font: ${(props) => props.theme.ctaLink.font};
    text-decoration: ${(props) => props.theme.ctaLink.textDecoration};
    box-shadow: none;
  }

  &.fpt-btn-link:hover {
    text-decoration: ${(props) => props.theme.ctaLink.hover_textDecoration};
  }

  &.fpt-btn-link img.fpt-cta-button__logo {
    filter: ${(props) => props.theme.ctaLink.filter};
    height: ${(props) => props.theme.ctaLink.iconHeight};
    margin: 0 10px 0 0;
  }

  &.fpt-btn-link img.fpt-cta-button__arrow {
    display: none;
  }

  margin: ${(props) => props.theme.ctaButton.margin};
`

export default Button
