import styled from 'styled-components';

export const Header = styled.div`
  font-family: 'Rubik600' !important;
  font-stretch: normal;
  font-style: normal;
  margin-top: 40px;
  font-weight:bold;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  font-family: 'Rubik600';
  font-size: 18px;
  line-height: 1.25;
  color: #1e4e5f;
`;

export const Text = styled.p`
  font-family: 'Inter400';
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  margin-top: 20px;
  padding-left:40px;
  padding-right:40px;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-slate-blue);
`;

export const QRCodeContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
`;
export const QRCodeSpinnerContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  padding: 40px;
  padding-bottom: 41px;
`;