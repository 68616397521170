import React from 'react';
import smallLogo from '../../assets/images/smallLogo.svg';
import { Trans } from 'react-i18next';
import './Footer.css';

interface FooterProps {
  showLogo?: boolean;
  showLinks?: boolean;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  showLogo,
  showLinks,
}) => {
  return (
    <div className="fpt-c-footer-container">
      {showLogo && (
        <p>
          <img src={smallLogo} alt="logo" />
        </p>
      )}
      {showLinks && (
        <>
          <p className="fpt-c-link-p">
            <a href="https://footprinttech.de/privacy-policy-webapp/" target="_blank" className="fpt-c-link-text">
              <Trans i18nKey="common.privacyPolicy"></Trans>
            </a>
          </p>
          <p className="fpt-c-link-p">
            <a href="https://footprinttech.de/imprint-webapp/" target="_blank" className="fpt-c-link-text">
              <Trans i18nKey="common.imprint"></Trans>
            </a>
          </p>
        </>
      )}
    </div>
  );
};

export { Footer }; 