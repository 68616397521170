import EventEmitter from 'eventemitter3';

export interface CreateMatchingDto {
  article_number: string;
  session_id: string;
  measurement_id: string;
}

export interface Measurement {
  measurement_id: string;
  session_id: string;
  created_at: Date;
}

export interface OutcomeProps {
  matching: any;
  articleName: string;
  articleManufacturer?: string;
  productSrc: string;
  sizes: Array<any>;
  sum?:string;
  measurementId?: any;
  profile?: any;
  sessionId:string;
  emitter: EventEmitter;
}

export interface IMatchingComponentProps {
  measurementId: string;
  existingMatching?:any;
  sessionId: string;
  articleNumber: string;
  articleName: string;
  articleId: string;
  theme: any;
  lang: string;
  productSrc: string;
  sizes: Array<string>;
  sum?: string;
  emitter: EventEmitter;
}

export const enum MatchingState {
  UNDEFINED, SUCCESS, ERROR, PROCESSING,  UNAVAILABLE,
}

export interface IMatchingComponentState {
  status: MatchingState,
  matching: any
}

export const enum Availability {
  AVAILABLE, UNAVAILABLE, ALTERNATIVES,
}
