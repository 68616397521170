import React, { FunctionComponent, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { AsyncButton } from '../Button';
import { FontsStyle } from '../FontsStyle';
import { FormattedMessage } from 'react-intl';
import { ArticleData, Matching } from '../../interfaces/ProductService.interface';
import { CtaIcon } from './CtaIcon';
import Events from './Events';
import EventEmitter from 'eventemitter3';
import { ArticleContext } from '../../FootprintTech/AppController';

const Arrow = styled.img`
  margin: 0 0 0 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
`;

const Label = styled.span`
  text-align: center;
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface CtaButtonProps extends React.ComponentPropsWithRef<'button'> {
  matching: Matching;
  buttonPadding?: string;
  state?: string;
  emitter: EventEmitter,
  article: ArticleData,
}

export const CtaButton: FunctionComponent<CtaButtonProps> = (props) => {

  const { article, emitter } = props;
  const theme = useContext(ThemeContext);

  return (
    <>
      <FontsStyle />
      <AsyncButton
        type="button"
        {...props}
        style={{ padding: theme.ctaButton.padding }}
        onClick={() => emitter.emit(Events.onCtaPress, article)}
      >
        <Label className="fpt-cta-button__label">
          <CtaIcon />
          <FormattedMessage id={theme.ctaButton.label} />
        </Label>
        {/* <Arrow className="fpt-cta-button__arrow" src={arrowRight} /> */}
      </AsyncButton>
    </>
  )
}
