import React from 'react'
import { arrowBox } from '../../assets/Images'
import styled from 'styled-components'

const Arrow = styled.img.attrs((props) => ({
  ...props,
  src: arrowBox,
  alt: 'Arrow Icon',
  className: 'fpt-cta-button__arrow',
}))`
  margin: 0 0 0 30px;
  width: 40px;
  vertical-align: middle;
`
const ArrowItem = () => <Arrow />

export default ArrowItem
