import React from 'react';

const css = `

  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: hidden;
    display: block;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  #shopify-section-announcement-bar {
    display: none;
  }

  #shopify-section-custom-announcement-bar {
    display: none;
  }

  .header-wrapper {
    display: none;
  }

  .site-box {
    display: block;
  }

`;

function GlobalStyle() {
  return (<style>{css}</style>);
}

export default GlobalStyle;
