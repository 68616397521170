import { Matching, ShopSettings } from '../interfaces/ProductService.interface';

// Polyfill Object.hasOwn method for older brwsers
('hasOwn' in Object) || (Object.hasOwn = Object.call.bind(Object.hasOwnProperty));

export interface StorageState {
  sessionId: string;
  matchings: any;
  viewedTooltips: Array<string>;
  settings: ShopSettings;
}

interface LocalState {
  measurementId: string;
  anonymousId: string;
}

function arrayToUUIDString(array) {
  var hexValues = Array.from(array, function (byte:number) {
    return ('0' + (byte & 0xff).toString(16)).slice(-2);
  });

  return [
    hexValues.slice(0, 4).join(''),
    hexValues.slice(4, 6).join(''),
    hexValues.slice(6, 8).join(''),
    hexValues.slice(8, 10).join(''),
    hexValues.slice(10).join(''),
  ].join('-');
}

function generateUUID() {
  var array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  array[6] = (array[6] & 0x0f) | 0x40;  // Version 4
  array[8] = (array[8] & 0x3f) | 0x80;  // Variant 10
  return arrayToUUIDString(array);
}

class StorageService {
  sessionState: StorageState;

  localState: LocalState;

  constructor() {

    const sessionStr = sessionStorage.getItem('fpt-session');
    const localStr = localStorage.getItem('fpt-local');
    const domain = window.location.hostname;

    if (!crypto.randomUUID) {
      crypto.randomUUID = generateUUID;
    }

    this.localState = localStr ? JSON.parse(localStr) : {
      measurementId: '',
      anonymousId: crypto.randomUUID(),
    };

    this.sessionState = sessionStr ? JSON.parse(sessionStr) : {
      sessionId: '',
      matchings: {},
      viewedTooltips: [],
      settings: {
        name: domain
      }
    };

    if (!sessionStr) {
      sessionStorage.setItem('fpt-session', JSON.stringify(this.sessionState));
    }

    if (!localStr) {
      localStorage.setItem('fpt-local', JSON.stringify(this.localState));
    }

  }

  update(key:string, value:any) {

    if (Object.hasOwn(this.localState, key) || key === 'measurementId') {
      this.localState[key] = value;
    } else {
      this.sessionState[key] = value;
    }

    this.persistState();
  }

  clearMatchings() {
    this.sessionState.matchings = {};
    this.persistState();
  }

  setMeasurementId(measurementId: string) {
    this.localState.measurementId = measurementId;
    this.persistState();
  }

  clearMeasurement() {
    this.localState.measurementId = '';
    this.persistState();
  }

  findMatching(id:string):Matching {
    return this.sessionState.matchings[id] ? this.sessionState.matchings[id] : null;
  }

  getMeasurementId() {
    return this.localState.measurementId;
  }

  getSessionId() {
    return this.sessionState.sessionId;
  }

  getAnonymousId() {
    return this.localState.anonymousId;
  }

  addViewedTooltip(messageId:string): number {
    if (this.isTooltipViewed(messageId)) {
      return this.sessionState.viewedTooltips.length;
    }

    if (this.sessionState.viewedTooltips) {
      const result = this.sessionState.viewedTooltips.push(messageId);
      this.persistState();
      return result;
    }

    this.sessionState.viewedTooltips = [ messageId ];
    this.persistState();
    return this.sessionState.viewedTooltips.length;
  }

  findViewedTooltip(messageId: string): string | undefined {

    if (!this.sessionState.viewedTooltips) {
      return;
    }

    return this.sessionState.viewedTooltips.find(item => item === messageId);
  }

  isTooltipViewed(messageId: string) {
    return !!this.findViewedTooltip(messageId);
  }

  addMatching(m:Matching, id:string) {
    this.sessionState.matchings[id] = m;
    this.persistState();
    return m;
  }

  persistState() {
    sessionStorage.setItem('fpt-session', JSON.stringify(this.sessionState));
    localStorage.setItem('fpt-local', JSON.stringify(this.localState));
  }
}

export default new StorageService();
