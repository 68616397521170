import { AxiosInstance } from 'axios';
import axios from 'axios';

export interface PurchaseData {
  'page_type': string;
  'event': string;
  'currency': string;
  'totalValue': number;
  'tax': number;
  'transaction_id': string;
  'productPrice': Array<any>,
  'productQuantity': Array<any>,
  'productId': Array<any>,
}

interface ClientConfig {
  apiKey: string,
  baseUrl: string
}

export interface MatchData {
  matching_id: string;
  article_id: string;
}

export class ConversionsService {

  private client: AxiosInstance;

  constructor(config:ClientConfig) {
    console.log('[driver] instantianting conversion service for', window.location.hostname);
    this.client = axios.create({
      baseURL: config.baseUrl,
    });

    if (config.apiKey) {
      this.client.defaults.headers.common['X-API-KEY'] = config.apiKey;
    }
  }

  setApiKey(apiKey: string) {
    this.client.defaults.headers.common['X-API-KEY'] = apiKey;
  }

  findPurchase() {
    let result = null;
    if (window.dataLayer && window.dataLayer.length > 0) {
      result = window.dataLayer.find((item: PurchaseData) =>
        item.page_type == 'purchase' && item.event == 'sm_purchase');
    }
    if (result) {
      // purchase
      // order_number
      const purchase_event = (window as any).purchase_event;
      result.transaction_id = purchase_event ?
        purchase_event.transaction_id : result.transaction_id;
    }
    return result;
  }

  hasPurchase(match: MatchData, purchase: PurchaseData) {
    console.log('[driver] looking for purchase in matches', match);
    const index = purchase.productId.findIndex(id => id == match.article_id);
    const result = index > -1;
    if (result) {
      console.log('[driver] found purchase!');
    } else {
      console.log('[driver] no purchase found!');
    }
    return result;
  }

  getConversionData(match: MatchData, purchase: PurchaseData) {
    const index = purchase.productId.findIndex(id => id == match.article_id);
    return {
      matching_id: match.matching_id,
      price: Number(purchase.productPrice[index]),
      quantity: Number(purchase.productQuantity[index]),
      tax: Number(purchase.tax),
      total: Number(purchase.totalValue),
      currency: purchase.currency,
      client_session_id: purchase.transaction_id,
    };
  }

  postConversion(payload:any) {
    this.client.post('conversions', payload)
      .then(()      => console.log('[driver] conversion posted', payload))
      .catch((err)  => console.error(
        '[driver] something went wrong posting conversion', err));
  }

  handleConversion(purchase: PurchaseData) {
    console.log('[driver] processing purchase', purchase);
    const fptSession = sessionStorage.getItem('fpt-session');
    const sessionData = JSON.parse(fptSession);

    const promises = sessionData.matchings
      .filter((match: MatchData) => this.hasPurchase(match, purchase))
      .map((match: MatchData) => {
        const data = this.getConversionData(match, purchase);
        console.log('[driver] posting conversion data', data);
        return this.postConversion(data);
      });

    return Promise.all(promises)
      .then(() => console.log('[driver] conversions completed'))
      .catch((err) => console.log('[driver] Something went wrong persisting conversions', err));
  }

}

export default new ConversionsService({ baseUrl: process.env.API_URL, apiKey: '' });
