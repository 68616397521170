import React from 'react';
import styled from 'styled-components';
import Spinner from '../Button/Spinner';

const Panel = styled.div`
  background: ${props => props.theme.ctaButton.background};
  width: ${props => props.theme.ctaButton.width};
`;

const Processing = () => {
  return (
    <Panel>
      <Spinner />
    </Panel>
  );
}

export default Processing;
