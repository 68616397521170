import React, { useEffect } from 'react';
import { RecommendationOptions } from './RecommendationOptions';
import { Button } from '../Common/Button';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DarkSlateBlue } from '../../theme/colors';
import { Events } from '@footprint/common-components';
import RecommendationEvents from './Events';
import sessionService from '../../services/SessionService';

export function UnavailableOptions(props: any) {
  const { matching, emitter } = props;

  const handleBackToShop = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    console.log('[driver] Back to the shop called', matching)
    emitter.emit(RecommendationEvents.onRecommendationBackToShopPress, matching);
  }

  const handleNewMeasurement = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    console.log('[driver] Handle New Measurement called')
    emitter.emit(Events.onMeasureAgainPress);
  };

  useEffect(() => {
    sessionService.updateExitCode('recommendation', 'back-to-shop-option');
  }, []);

  const Text = styled.p`
    line-height: 12px !important;
    font-size: 10px;
    font-family: 'SourceSans3';
    margin-top: 20px;

    & a {
      color: ${DarkSlateBlue} !important;
      font-size: 10px;
      font-family: 'SourceSans3';
      text-decoration: underline;
    }

    & a:any-link {
      color: ${DarkSlateBlue};
      font-size: 10px;
      font-family: 'SourceSans3';
      text-decoration: underline;
    }

    & a:-webkit-any-link {
      color: ${DarkSlateBlue};
      font-size: 10px;
      font-family: 'SourceSans3';
      text-decoration: underline;
    }
  `;

  return (
    <RecommendationOptions>
      <Button
        type="button"
        className="wide-primary"
        onClick={handleBackToShop}
      >
        <FormattedMessage id="drv.matching.unavailable.action"></FormattedMessage>
      </Button>
      <Button type="button" className="link-secondary" onClick={handleNewMeasurement}>
        <FormattedMessage id="drv.matching.available.action2"></FormattedMessage>
      </Button>
      <Text>
      <FormattedMessage id="drv.splashscreen.imprint"
              values={{
                imp: (chunks) => <a href="https://footprinttech.de/imprint-webapp/" target="_blank">{chunks}</a>,
       }} />
      </Text>
    </RecommendationOptions>
  );
}
