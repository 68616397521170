/* Define your translations */
export const i18nConfig: { [index: string]: any } = {
  en: {
    locale: 'en',
    name: 'English',
    messages: {
      'drv.cta-button.label': 'Scan and find your size',
      'drv.cta-button.tooltip.text': 'Measure with your smartphone for your individual size recommendation.',
      'drv.cta-button.tooltip.action': 'Got it!',
    },
  },
  de: {
    locale: 'de',
    name: 'Deutsch',
    messages: {
      'drv.cta-button.label': 'Scanne und finde deine Größe',
      'drv.cta-button.tooltip.action': 'Verstanden',
      'drv.cta-button.tooltip.text': 'Miss mit dem Smartphone für deine individuelle Größenempfehlung',
    },
  },
  es: {
    locale: 'es',
    name: 'Español',
    messages: {
      'drv.cta-button.label': 'Escanea y encuentra tu talla',
      'drv.cta-button.tooltip.text': 'Mide con tu dispositivo móvil para obtener tu recomendación de talla individual.',
      'drv.cta-button.tooltip.action': '¡Entendido!',
    },
  },
  ja: {
    locale: 'ja',
    name: '日本語',
    messages: {
      'drv.cta-button.label': 'スキャンして自分のサイズを見つける',
      'drv.cta-button.tooltip.action': 'OK',
      'drv.cta-button.tooltip.text': 'スマートフォンを用いて足を測定し、最適なシューズのサイズを提案します。',
    },
  },
  fr: {
    locale: 'fr',
    name: 'Français',
    messages: {
      'drv.cta-button.label': 'Trouvez la bonne pointure',
      'drv.cta-button.tooltip.action': 'Compris',
      'drv.cta-button.tooltip.text': 'Mesure tes pieds avec ton smartphone pour obtenir une recommandation de pointure personnalisée.',
    },
  },
  pl: {
    locale: 'pl',
    name: 'Polski',
    messages: {
      'drv.cta-button.label': 'Skanuj i znajdź swój rozmiar',
      'drv.cta-button.tooltip.action': 'Rozumiem!',
      'drv.cta-button.tooltip.text': 'Zmierz za pomocą smartfonu dla indywidualnej rekomendacji rozmiaru.',
    },
  },
  tr: {
    locale: 'tr',
    name: 'Türkçe',
    messages: {
      'drv.cta-button.label': 'Tarayın ve ayak ölçünüzü bulun',
      'drv.cta-button.tooltip.action': 'Anladım!',
      'drv.cta-button.tooltip.text': 'Kişisel boyut öneriniz için telefonunuzla ölçüm yapın.',
    },
  },
};
