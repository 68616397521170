import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { co2Icon ,productSrcPlaceHolder } from '../../../assets';
import EventEmitter from 'eventemitter3';
import Events from '../../Recommendation/Events';
import RenderUtil from '../../../util/RenderUtil';

const Panel = styled.div`
  overflow: visible;
  padding: 20px 20px 20px 20px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  text-align: left;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  margin:30px;
`

const Icon = styled.img`
    display: inline;
    width: 140px;
    object-fit: contain;
    align-self: center;
    border-radius: 10px;
    overflow: hidden;

`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  width:50%;
`

const Line = styled.div`
  width: 100%;
  height: 1px !important;
  opacity: 0.3;
  margin: 9px 0;
  background-color: #1e4e5f;
  display: block !important;
`

const SubText = styled.div`
    font-family: 'Inter500';
    font-size: 12px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--dark-slate-blue);
`
const ImageContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-self: center;
  border-radius: 10px;
  padding: 6px 5px 5px 6px;
  background-color: #f0f0f0;
`;
const SubHeader = styled.div`
    font-family: 'Inter700';
    font-size: 16px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--dark-slate-blue);
`
const BottomText = styled.div`
  margin: 20px 0 0;
  text-align: center;
  font-family: 'Inter500';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: var(--dark-slate-blue);
`
const CO2Icon = styled.img`
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translateX(-50%);
`;

const SizeText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

const SizeNumber = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter700';
`;

const SizeDescription = styled.span`
  display: inline-block;
  margin-left: 5px;
  font-size: 10px;
  font-weight: normal;
  font-family: 'Inter500';
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;


type Props = {
  articleName?: string
  manufacturer?: string
  productSrc?: string
  matching?: any
  sum?:any,
  emitter: EventEmitter
}

const shortenText = (text, maxLength) => {
  if (text.length > maxLength ) {
    const partLength = Math.floor((maxLength - 1) / 2); // Subtract one for the ellipsis
    return `${text.slice(0, partLength)}…${text.slice(-partLength)}`;
  }
  return text;
};

const ArticleInfo: React.FunctionComponent<Props> = (props) => {
  const { articleName, manufacturer, productSrc, matching, sum, emitter } = props
  const shortArticleName = articleName;
  const [isBottomTextVisible, setIsBottomTextVisible] = useState(false);
  const matchId = matching.matching_id;
  const { size } = matching;

  const sizeNumber = size.value;

  const isTryon = window.location.hostname === 'tryon.footprinttech.de';
  const sizeDescription = isTryon ?
    matchId.substring(matchId.length - 4) :
    size.description;;

  return (
    <Panel>
      <div style={{display: 'flex'}}>
        <ImageContainer>
          <Icon src={productSrc !== '' && productSrc !== undefined ? productSrc : productSrcPlaceHolder} />
          <CO2Icon src={co2Icon} onClick={() => {
            setIsBottomTextVisible(!isBottomTextVisible);
            emitter.emit(Events.onCO2Press);
          }} />
        </ImageContainer>
        <ContentWrapper>
          {manufacturer && (
            <div>
               <SubHeader>{manufacturer}</SubHeader>
            </div>
          )}
           <div>
            {shortArticleName && <SubText>{shortArticleName}</SubText> }
            {(shortArticleName || manufacturer) &&  <Line /> }
          </div>
          <div>
            <SubHeader>
             <SizeText>
                <SizeNumber>{sizeNumber}</SizeNumber>
                <SizeDescription>{sizeDescription}</SizeDescription>
              </SizeText>
            </SubHeader>
            <SubText><FormattedMessage id="drv.matching.size.label"></FormattedMessage></SubText>
            {sum && <Line /> }
          </div>
          {sum &&
           <div>
            <SubHeader>{sum}</SubHeader>
            <SubText><FormattedMessage id="drv.matching.price.label"></FormattedMessage></SubText>
          </div>
          }

        </ContentWrapper>
      </div>
      {isBottomTextVisible && (
        <BottomText>
              <FormattedMessage id="drv.co2feature.greenzone"></FormattedMessage>
        </BottomText>
      )}
    </Panel>
  );
};

export default ArticleInfo;
