import axios, { AxiosInstance } from 'axios';

export interface ICreateSessionData {
  screen_width: number;
  screen_height: number;
  analytics_accepted: boolean;
  client_session_id: string | null;
}

interface IArticleData {
  articleNumber: string;
  articleName: string;
  gtin: string;
}

class ArticleService {
  private client: AxiosInstance;

  private domain: string;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.API_URL,
    });
  }

  setSessionId(sessionId: string) {
    this.client.defaults.headers.common.Authorization = `Bearer ${sessionId}`;
  }

  lookupArticle(data: IArticleData) {
    console.log('[driver] looking for article', data);
    const params = {} as any;

    if (data.articleNumber) {
      params.number = data.articleNumber;
    } else if (data.gtin) {
      params.gtin = data.gtin;
    }

    return this.client.get('/articles', {
      params,
    });
  }

}

export default new ArticleService();
