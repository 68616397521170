import React, { CSSProperties } from 'react';
import './ErrorPanel.css';
import ErrorHeader from './ErrorHeader';
import ErrorInfoBox from './ErrorInfoBox';
import { ErrorCodeParser, InfoBox } from './ErrorParser';
import ErrorActions from './ErrorActions';
import { Footer } from '../Footer/Footer';
import EventEmitter from 'eventemitter3';

interface PropsWithChildrenNodes {
  children?: React.ReactNode;
  code: string | undefined;
  source: string | undefined; 
  showFooter: boolean | true;
  hideAction: boolean | false; 
  emitter: EventEmitter;
}
const ErrorPanel = (props: PropsWithChildrenNodes) => {
  const errorObject = ErrorCodeParser.parseErrorCode(
    props?.code !== undefined ? props.code : 'YUR04-00'
  );

  const { emitter,showFooter  } = props;


  const hide: CSSProperties = {
    display: 'none',
  };
  const show: CSSProperties = {
    display: 'block',
    textAlign:'center'
  };

  const footerStyle: CSSProperties = props?.code === 'A404' ? hide : show;
  const fptCErrorEmptySpaceStyle:CSSProperties = {"height": "100%"};

  return ( 
      <>
          {/* HEADER */}
          <ErrorHeader 
            titleTransId={errorObject.messageTitle}
            subTitleTransId={errorObject.messageBodyTransId}
            pageAlignment={
              errorObject.centeredDesign ? 'fpt-c-error-centered' : ''
            }
            errorIcon={errorObject.icon}
            errorCode={errorObject.fullErrorCode}
            isFullSizeIcon={errorObject.isFullSizeIcon}
          ></ErrorHeader>
          {errorObject.infoBox != InfoBox.NA && (
            <ErrorInfoBox
              message={errorObject.messageInfoboxTransId}
              isLongArrowVisible={errorObject.isLongArrowVisible}
              videoUrl={errorObject.videoUrl}
              isTryToVisible={errorObject.isTryToVisible}
              variant={errorObject.infoBox}
            ></ErrorInfoBox>
          )}
          {errorObject.infoBox == InfoBox.NA && (
            <div className="fpt-c-error-empty-space"></div>
          )}
          {/* ACTION BUTTON */}
          {errorObject.action1 && !props.hideAction && (
            <ErrorActions
              source={props?.source}
              code={props?.code}
              actionOrder={1} 
              actionText={errorObject.labelAction1}
              action={errorObject.action1}
              constr={{ width: 1920, ratio: 1.5 }}
              emitter={emitter}
            ></ErrorActions>
          )}
          {/* FOOTER MESSAGE */}
          {errorObject.isFooterVisible && (
            <div className="fpt-c-error-footer-message" style={footerStyle}>
              {errorObject.hasFooterActionMessage && (
                <p>{errorObject.messageFooter}</p>
              )}
              {errorObject.action2 && (
                <ErrorActions
                  source={props?.source}
                  code={props?.code}
                  actionOrder={2} 
                  actionText={errorObject.labelAction2}
                  action={errorObject.action2}
                  constr={{ width: 1920, ratio: 1.5 }}
                  emitter={emitter}
                ></ErrorActions>
              )}
            </div>
          )}
          {/* FOOTER LOGO */}
         {showFooter &&  
          <>
            <div style={fptCErrorEmptySpaceStyle} className="fpt-c-error-empty-space"></div>
            <Footer showLinks={true} showLogo={true}></Footer>
          </>
          }
      </> 
  );
};

export { ErrorPanel };
