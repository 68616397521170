import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../Common/Button';
import { FormattedMessage } from 'react-intl';
import { RecommendationOptions } from './RecommendationOptions';
import { ModalService } from '../Common/Modal';
import { DarkSlateBlue } from '../../theme/colors';
import { Events } from '@footprint/common-components';
import RecommendationEvents from './Events';
import sessionService from '../../services/SessionService';

const Text = styled.p`
  line-height: 12px !important;
  font-size: 10px;
  font-family: 'SourceSans3';
  margin-top: 20px;
  color: ${DarkSlateBlue} !important

  & a {
    color: ${DarkSlateBlue} !important;
    font-size: 10px;
    font-family: 'SourceSans3';
    text-decoration: underline;
  }

  & a:any-link {
    color: ${DarkSlateBlue};
    font-size: 10px;
    font-family: 'SourceSans3';
    text-decoration: underline;
  }

  & a:-webkit-any-link {
    color: ${DarkSlateBlue};
    font-size: 10px;
    font-family: 'SourceSans3';
    text-decoration: underline;
  }
`;


export function AvailableOptions(props: any) {
  const { matching, emitter } = props;

  const handleMatching = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    console.log('[driver] Handle Matching called', matching);
    ModalService.close();
    emitter.emit(RecommendationEvents.onAddToCartPress, matching);
  };

  const handleNewMeasurement = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    console.log('[driver] Handle New Measurement called');
    ModalService.close();
    emitter.emit(Events.onMeasureAgainPress);
  }

  useEffect(() => {
    sessionService.updateExitCode('recommendation', 'add-to-cart-option');
  }, []);

  return (
    <RecommendationOptions>
    <Button type="button" className="wide-primary" onClick={handleMatching}>
      <FormattedMessage id="drv.matching.available.action"></FormattedMessage>
    </Button>
    <Button type="button" className="link-secondary" onClick={handleNewMeasurement}>
      <FormattedMessage id="drv.matching.available.action2"></FormattedMessage>
    </Button>
    <Text>
      <FormattedMessage id="drv.matching.available.message"></FormattedMessage>
      <br/><br/><br/>
      <FormattedMessage id="drv.splashscreen.imprint"
              values={{
                imp: (chunks) => <a href="https://footprinttech.de/imprint-webapp/" target="_blank">{chunks}</a>,
       }} />
    </Text>
  </RecommendationOptions>
  )
}
