import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }`;

const Spinner = styled.img`
    animation: ${rotation} 2s infinite linear;
  `;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 5rem;
`;

const Message = styled.div`
  margin-bottom: 5rem;
  width: 19.688rem;
  color: #1e4e5f;
`;

export { Panel, Message, Spinner };
