/* eslint-disable @typescript-eslint/lines-between-class-members */
import axios from 'axios';
import React from 'react';

export class ShopwareHelper {

  SHOPWARE_DOMAIN: string;
  SHOPWARE_KEY: string;
  SHOPWARE_USER: string;
  SHOESIZE_GROUP: string;

  constructor(domain, user, key, shoesize) {
    this.SHOPWARE_DOMAIN = domain;
    this.SHOPWARE_USER = user;
    this.SHOPWARE_KEY = key;
    this.SHOESIZE_GROUP = shoesize;
  }

  isArticleAvailable(article) {
    return article.active && article.inStock > 0;
  }

  getShoeSizeFromArticle(article, groupId) {
    const option = article.configuratorOptions.find(
      item => {
        return item.groupId == groupId;
      });
    return option.name;
  }

  getCurrentArticleShoeSize(article:any, groupId:any) {
    const found = article.mainDetail.configuratorOptions
    .find(item => item.groupId == groupId);
    return found.name;
  }

  findArticleByShoesize(article: any, size: any, groupId: any) {
    return article.details
      .filter(item => this.isArticleAvailable(item))
      .find((articleDetails: any) => {
        const articleSize = this.getShoeSizeFromArticle(articleDetails, groupId);
        return articleSize == size;
      });
  }

  openBasketInventory() {
    const basketButton: HTMLButtonElement = document.querySelector('a[title="Warenkorb"]');
    if (!basketButton) {
      console.error('[driver] button a[title="Warenkorb"] not found');
      return;
    }
    basketButton.click();
  }

  getArticleById(articleId) {
    const domainName = this.SHOPWARE_DOMAIN.replace('https://', '');
    return axios.get(
      `https://${this.SHOPWARE_USER}:${this.SHOPWARE_KEY}@${domainName}api/articles/${articleId}`, {
        auth: {
          username: this.SHOPWARE_USER,
          password: this.SHOPWARE_KEY,
        },
      })
      .then(response => response.data.data);
  }

  addToCart(article, size, token?:string) {

    const csrfToken = token ? token : document.querySelector("input[name='__csrf_token']")
      .getAttribute('value');

    console.log('[driver] Shoe Size group', this.SHOESIZE_GROUP);

    const group = article.configuratorSet.groups
      .find(item => item.name === this.SHOESIZE_GROUP);

    const currentSize = this.getCurrentArticleShoeSize(article, group.id);

    const articleBySize = size == currentSize ? {
      number: article.mainDetail.number } :
      this.findArticleByShoesize(article, size, group.id);

    if (!articleBySize) {
      console.error(`[driver] Shoe size ${size} not available in shopware API`);
      alert(`Schuhgröße ${size} nicht verfügbar`);
      return Promise.reject(new Error(`Schuhgröße ${size} nicht verfügbar`));
    }

    const form = new FormData();

    form.append('sAdd', articleBySize.number);
    // form.append('sAdd', size);
    form.append('sQuantity', '1');
    form.append('isXHR', '1');
    form.append('__csrf_token', csrfToken);
    form.append('sActionIdentifier', '');
    form.append('sAddAccessories', '');

    const options = { headers: {} };
    options.headers['X-CSRF-Token'] = csrfToken;

    return axios.post(this.SHOPWARE_DOMAIN + 'checkout/ajaxAddArticleCart', form, options)
      .then(response => console.log('[driver] Add To cart response', response))
      .then(() => this.openBasketInventory());
  }

  submitAddToCart(articleNumber) {
    const csrfToken = document.querySelector("input[name='__csrf_token']")
      .getAttribute('value');

    const form = new FormData();

    form.append('sAdd', articleNumber);
    form.append('sQuantity', '1');
    form.append('isXHR', '1');
    form.append('__csrf_token', csrfToken);
    form.append('sActionIdentifier', '');
    form.append('sAddAccessories', '');

    const options = { headers: {} };
    options.headers['X-CSRF-Token'] = csrfToken;

    return axios.post(this.SHOPWARE_DOMAIN + 'checkout/ajaxAddArticleCart', form, options)
      .then(response => console.log('[driver] Add To cart response', response))
      .then(() => this.openBasketInventory());
  }

}
