export const Events = {
  onCancelPress: 'onCancelPress',
  onMeasureAgainPress: 'onMeasureAgainPress',
  onCameraPermissionRequest: 'onCameraPermissionRequest',
  onLetUsKnowPress: 'onLetUsKnowPress',
  onTellMePress: 'onTellMePress',
  onBackToShopPress: 'onBackToShopPress',
  onHelpCenterPress: 'onHelpCenterPress',
  onBackToFootprintPress: 'onBackToFootprintPress',
  onStartAgainPress: 'onStartAgainPress',
  onMeasurementPress: 'onMeasurementPress'
};