import axios, { AxiosInstance } from 'axios';

export interface ICreateSessionData {
  screen_width: number;
  screen_height: number;
  analytics_accepted: boolean;
  client_session_id: string | null;
  referrer: string;
}

class SessionService {
  private client: AxiosInstance;

  private updateClient: AxiosInstance;

  private sessionId: string;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.API_URL,
    });
    this.updateClient = axios.create({
      baseURL: process.env.API_URL,
    });
  }

  createSession(data: ICreateSessionData) {
    console.log('[SessionService] creating session');
    // TODO: If create session fails we can handle it here
    return this.client.post('sessions', data)
      .then(result => result.data.session_id);
  }

  setSessionId(sessionId: string) {
    this.sessionId = sessionId;
    this.updateClient.defaults.headers.common.Authorization = `Bearer ${sessionId}`;
  }

  setApiKey(apiKey: string) {
    this.client.defaults.headers.common['X-API-KEY'] = apiKey;
  }

  getSession() {
    return this.updateClient.get(`sessions/${this.sessionId}`)
      .then(result => result.data);
  }

  getSessionMeasurements() {
    return this.updateClient.get(`sessions/${this.sessionId}/measurements`)
      .then(result => result.data);
  }

  getSessionEvents() {
    return this.client.get(`sessions/${this.sessionId}/events`)
      .then(result => result.data);
  }

  updateExitCode(source:string, code:string) {
    if (!this.sessionId) {
      console.warn('[driver] update session failed, missing session');
    }
    return this.updateClient.patch(`sessions/${this.sessionId}`, { exit_code: `${source}.${code}` });
  }

  updateSession(sessionId:string, update:any) {
    return this.updateClient.patch(`sessions/${sessionId}`, update);
  }
}

export default new SessionService();
