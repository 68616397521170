import React from 'react';
import styled from 'styled-components';

export const CtaIcon = styled.img.attrs((props) => ({
  src: props.theme.ctaButton.icon,
  alt: 'Footprint Logo',
  className: 'fpt-cta-button__logo'
}))`
  vertical-align: middle;
  display: inline-block;
  filter: ${props => props.theme.ctaButton.filter};
`;
