import React from 'react';
import { FormattedMessage } from 'react-intl';
import { spinner } from '../../../assets/Images';
import { Panel, Message, Spinner } from './Elements';
import cssGradientBG from '../../../assets/styles/gradient';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Processing(props: any) {
  return (
    <Panel>
      <style>{cssGradientBG}</style>
      <Message>
        <h1>
          <FormattedMessage id="drv.processing.title"></FormattedMessage>
        </h1>
        <p>
          <FormattedMessage id="drv.processing.message"></FormattedMessage>
        </p>
      </Message>
      <div>
        <Spinner src={spinner} />
      </div>
    </Panel>
  );
}
