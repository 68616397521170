import React, { CSSProperties } from 'react';
import './Button.css';

interface ButtonProps {
  children?: any;
  onClick?: () => void;
  variant?:
    | 'primary'
    | 'primary-rounded'
    | 'secondary'
    | 'danger'
    | 'link-primary'
    | 'link-primary-blue'
    | 'link-danger'
    | 'wide-primary'
    | 'wide-primary-round'
    | 'wide-secondary'
    | 'wide-secondary-no-margin'
    | 'wide-danger';
  disabled?: boolean;
}

const fptBtnStyle:CSSProperties = {
  padding: "1.32rem",
  borderWidth: "1px",
  borderStyle: "solid",
  fontSize: "0.9rem", // No default value provided for --text-size-medium
  fontWeight: 500,
  cursor: "pointer",
  display: "block"
};
const fptBtnPrimaryStyle:CSSProperties = {
  backgroundColor: "#1e4e5f", // --dark-slate-blue
  borderColor: "#322f30", // --btn-border-color-primary
  color: "#fefffe", // --btn-color-primary
  borderRadius: "0.66rem", // --btn-border-radius-primary
  margin: "1.32rem auto" // --btn-padding
};
const fptBtnPrimaryRoundedStyle:CSSProperties = {
  backgroundColor: "#1e4e5f", // --dark-slate-blue
  borderColor: "#322f30", // --btn-border-color-primary
  color: "#fefffe", // --btn-color-primary
  borderRadius: "2.66rem", // --btn-border-radius-primary-rounded
  margin: "1.32rem auto", // --btn-padding
  paddingLeft: "3.5rem",
  paddingRight: "3.5rem"
};
const fptBtnDisabledStyle:CSSProperties = {
  opacity: 0.1
};
const fptBtnWidePrimaryStyle:CSSProperties = {
  backgroundColor: "#1e4e5f", // --dark-slate-blue
  borderColor: "#322f30", // --btn-border-color-primary
  color: "#fff",
  borderRadius: "0.66rem", // --btn-border-radius-primary
  width: "100%",
  padding: "1.32rem", // --btn-padding
  margin: "1.32rem 0",
  transition: "all 0.3s",
};
const fptBtnWidePrimaryRoundStyle:CSSProperties = {
  backgroundColor: "#1e4e5f", // --dark-slate-blue
  borderColor: "#322f30", // --btn-border-color-primary
  color: "#fff",
  borderRadius: "100px",
  transition: "all 0.3s",
  paddingLeft: "3.125rem",
  paddingTop: "1.062rem",
  paddingRight: "3.125rem",
  paddingBottom: "1.062rem",
  border: "none",
};
const fptBtnSecondaryStyle:CSSProperties = {
  backgroundColor: "transparent", // --btn-bg-color-secondary
  borderColor: "#1e4e5f", // --dark-slate-blue
  color: "#1e4e5f", // --btn-color-secondary
  borderRadius: "0.66rem", // --btn-border-radius-secondary
};
const fptBtnWideSecondaryStyle:CSSProperties = {
  backgroundColor: "transparent", // --btn-bg-color-secondary
  borderColor: "#1e4e5f", // --dark-slate-blue
  color: "#1e4e5f", // --btn-color-secondary
  borderRadius: "0.66rem", // --btn-border-radius-secondary
  width: "100%",
  fontWeight: "bold",
  padding: "1.32rem", // --btn-padding
  margin: "1.32rem 0",
  transition: "all 0.3s",
};
const fptBtnWideSecondaryNoMarginStyle:CSSProperties = {
  backgroundColor: "transparent", // --btn-bg-color-secondary
  borderColor: "#1e4e5f", // --dark-slate-blue
  color: "#1e4e5f", // --btn-color-secondary
  borderRadius: "0.66rem", // --btn-border-radius-secondary
  width: "100%",
  fontWeight: "bold",
  padding: "1.32rem 0", // --btn-padding
  transition: "all 0.3s",
};
const fptBtnDangerStyle:CSSProperties = {
  backgroundColor: "#ff345e", // --reddish-pink
  color: "#fdf5ec", // --almond
  borderRadius: "0.66rem", // --btn-border-radius-primary
  margin: "1.32rem auto" // --btn-padding
};
const fptBtnWideDangerStyle:CSSProperties = {
  backgroundColor: "#ff345e", // --reddish-pink
  color: "#fdf5ec", // --almond
  borderRadius: "0.66rem", // --btn-border-radius-primary
  margin: "1.32rem auto", // --btn-padding
  width: "100%"
};
const fptBtnLinkPrimaryStyle:CSSProperties = {
  color: "#1e4e5f", // --dark-slate-blue
  textDecoration: "none",
  padding: "1.12rem",
  fontWeight: "bold",
  border: "none",
  background: "none",
  boxShadow: "none",
  display: "inline-block",
  backgroundColor: "transparent" // --btn-bg-color-link-primary
};
const fptBtnLinkPrimaryBlueStyle:CSSProperties = {
  color: "#00a5e2",
  textDecoration: "none",
  padding: "1.12rem",
  border: "none",
  background: "none",
  boxShadow: "none",
  display: "inline-block",
  backgroundColor: "transparent" // --btn-bg-color-link-primary
};
const fptBtnLinkDangerStyle:CSSProperties = {
  color: "#ff345e", // --reddish-pink
  textDecoration: "underline",
  border: "none",
  background: "none",
  boxShadow: "none",
  display: "inline-block",
  backgroundColor: "transparent" // --btn-bg-color-link-danger
};
const getButtonStyle = (variant: string, disabled: boolean): CSSProperties => {
  let style = fptBtnStyle;
  switch (variant) {
      case 'primary':
          style = { ...style, ...fptBtnPrimaryStyle };
          break;
      case 'primary-rounded':
          style = { ...style, ...fptBtnPrimaryRoundedStyle };
          break;
      case 'secondary':
          style = { ...style, ...fptBtnSecondaryStyle };
          break;
      case 'danger':
          style = { ...style, ...fptBtnDangerStyle };
          break;
      case 'link-primary':
          style = { ...style, ...fptBtnLinkPrimaryStyle };
          break;
      case 'link-primary-blue':
          style = { ...style, ...fptBtnLinkPrimaryBlueStyle };
          break;
      case 'link-danger':
          style = { ...style, ...fptBtnLinkDangerStyle };
          break;
      case 'wide-primary':
          style = { ...style, ...fptBtnWidePrimaryStyle };
          break;
      case 'wide-primary-round':
          style = { ...style, ...fptBtnWidePrimaryRoundStyle };
          break;
      case 'wide-secondary':
          style = { ...style, ...fptBtnWideSecondaryStyle };
          break;
      case 'wide-secondary-no-margin':
          style = { ...style, ...fptBtnWideSecondaryNoMarginStyle };
          break;
      case 'wide-danger':
          style = { ...style, ...fptBtnWideDangerStyle };
          break;
      default:
          break;
  }
  if (disabled) {
      style = { ...style, ...fptBtnDisabledStyle };
  }
  return style;
};
const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const { children, onClick, variant = 'primary', disabled = false } = props;
  const buttonStyle = getButtonStyle(variant, disabled);
  return !disabled ? (
    <button type="button" style={buttonStyle} className={`fpt-btn fpt-btn--${variant}`} onClick={onClick}>
      {children}
    </button>
  ) : (
    <button type="button" style={buttonStyle} className={`fpt-btn fpt-btn--${variant} fpt-btn-disabled`}>
      {children}
    </button>
  );
};

export { Button };
