import { useRef, useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useMeasure = () => {
  const ref = useRef<HTMLObjectElement>(null);
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const ro = new ResizeObserver(([entry]) => set(entry.contentRect));
  useEffect(() => {
    if (ref && ref.current) {
      ro.observe(ref.current);
      return () => ro.disconnect();
    }
    return () => ro.disconnect();
  }, [ref.current]);
  return { bounds, ref };
};
