import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Header, Text, QRCodeSpinnerContainer } from './Elements';
import Spinner from '../Button/Spinner';

const RedColor = styled.div`
  color: red;
  bottom: 1px;
  position: absolute;
`;
const PollingPanel = function(props: any) {

  return (<>
    <Header>
      <FormattedMessage id="drv.qr-gateway-title" />
    </Header>
    <Text>
      <FormattedMessage id="drv.qr-gateway-message"   values={{
        b: (chunks) => <b>{chunks}</b>,
      }}/>
    </Text>
    <Text>
      <FormattedMessage
        id="drv.qr-gateway-hint"
        values={{
          b: (chunks) => <b>{chunks}</b>,
        }}
      />
    </Text>
    <QRCodeSpinnerContainer>
      <Spinner />
    </QRCodeSpinnerContainer>
    <Text>
      <RedColor>
      <FormattedMessage
        id="drv.qr-gateway-polling"
      /></RedColor>
    </Text>
    </>);
}

export default PollingPanel;
