/* eslint-disable @typescript-eslint/lines-between-class-members */
import AbstractShop from './AbstractShop';
import axios from 'axios';

const apiKey = 'f6b2d290-8846-5550-ab92-e8daff98f001';
const shopDomain = window.location.origin.includes('localhost') ? 'www.dummy.de' : window.location.origin;

function getSizes() {
  const sizeNodes = document.querySelectorAll('.product_option_select option');
  let key;
  const result = [];

  sizeNodes.forEach( (node: HTMLElement) => {
    key = node.innerText;
    result.push(key);
  });

  return result;
}

export class MyfootprintBigCartelService extends AbstractShop {

  constructor() {
    super();
    this.articleNumber = 'WMS_TABLE_FULL';
    this.articleName = document.querySelector('.product-title').innerHTML;
    this.productSrc = (document.querySelector('.product-image') as HTMLImageElement).src.split('?')[0];
    this.apiKey = apiKey;
    this.themeName = 'default';
    this.sizes = getSizes();
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': apiKey,
        'X-SHOP-DOMAIN': shopDomain,
      },
    });
  }

}
