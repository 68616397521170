import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Button } from '../Button';

const gradient = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const Spinner = styled.div`
  background: linear-gradient(90deg, #e4e4e4, #ffffff);
  background-size: 200% 200%;
  animation: ${gradient} 3s linear infinite;
  width: 50%;
  height: 15px;
  border-radius: 5px;
`;

function GradientSpinner() {
  return(<Button type="button"><Spinner/></Button>);
}

export default GradientSpinner;
