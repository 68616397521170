import close from './images/close.svg'
import camera from './images/camera.svg'
import tooltipCircle from './images/tooltip_circle.svg'
import lightBulb from './images/light_bulb.svg'
import closeBubble from './images/close_bubble.svg'
import fptLogo from './images/01_LOGO_G.png'
import fptLogoBlack from './images/logo.svg'
import fptLogoWhite from './images/fpt-logo-white.png'
import box from './images/box.svg'
import boxDark from './images/boxDark.svg'
import shoeIcon from './images/shoe.png'
import spinner from './images/spinner.svg'
import scale from './images/scale.svg'
import teaser1 from './images/teaser1.png'
import teaser2 from './images/teaser2.png'
import teaser3 from './images/teaser3.png'
import ellipse from './images/ellipse.svg'
import progress1 from './images/progress1.svg'
import progress2 from './images/progress2.svg'
import progress3 from './images/progress3.svg'
import underline from './images/underline.png'
import longUnderline from './images/long_underline.png'
import female0 from './images/avatars/female.svg'
import female1 from './images/avatars/female1.svg'
import female2 from './images/avatars/female2.svg'
import female3 from './images/avatars/female3.svg'
import female4 from './images/avatars/female4.svg'
import female5 from './images/avatars/female5.svg'
import female6 from './images/avatars/female6.svg'
import female7 from './images/avatars/female7.svg'
import female8 from './images/avatars/female8.svg'
import female9 from './images/avatars/female9.svg'
import female10 from './images/avatars/female10.svg'
import female11 from './images/avatars/female11.svg'
import female12 from './images/avatars/female12.svg'
import male0 from './images/avatars/male.svg'
import male1 from './images/avatars/male1.svg'
import male2 from './images/avatars/male2.svg'
import male3 from './images/avatars/male3.svg'
import male4 from './images/avatars/male4.svg'
import male5 from './images/avatars/male5.svg'
import male6 from './images/avatars/male6.svg'
import male7 from './images/avatars/male7.svg'
import male8 from './images/avatars/male8.svg'
import male9 from './images/avatars/male9.svg'
import male10 from './images/avatars/male10.svg'
import male11 from './images/avatars/male11.svg'
import splashHands from './images/save-earth@3x.png'
import loaderDots from './images/loader_three_dots.svg'
import yuri from './images/Yuri@3x.png'
import fptLogoSimple from './images/fpt_logo_simple.png'
import fptLogoLightBlue from './images/fpt_logo_light_blue.png'
import arrowRight from './images/arrow_right.png'
import arrowBox from './images/arrow_box.svg'
import loaderDotsBlack from './images/loader_three_dots_black.svg'
import shoesSpritesheet from './images/shoes_spritesheet.png'
import ringSpinner from './images/ring_spinner.png'
import co2Icon from './images/co2Icon.svg'
import shareIcon from './images/shareIcon.svg'
import productSrcPlaceHolder from './images/icon_footwear.png'

export const Images = {
  close,
  closeBubble,
  camera,
  tooltipCircle,
  lightBulb,
  fptLogo,
  fptLogoBlack,
  fptLogoWhite,
  fptLogoSimple,
  fptLogoLightBlue,
  box,boxDark,co2Icon,shareIcon,productSrcPlaceHolder
}

export {
  arrowRight,
  arrowBox,
  close,
  closeBubble,
  camera,
  tooltipCircle,
  lightBulb,
  fptLogo,
  fptLogoBlack,
  fptLogoWhite,
  fptLogoLightBlue,
  ringSpinner,
  shoeIcon,
  shoesSpritesheet,
  spinner,
  scale,
  box,boxDark,
  splashHands,
  loaderDots,
  loaderDotsBlack,
  yuri,
  fptLogoSimple,co2Icon,shareIcon,productSrcPlaceHolder
}

export const Teaser = {
  bg1: teaser1,
  bg2: teaser2,
  bg3: teaser3,
  ellipse,
  progress1,
  progress2,
  progress3,
  underline,
  longUnderline,
}

const male = [
  male0,
  male1,
  male2,
  male3,
  male4,
  male5,
  male6,
  male7,
  male8,
  male9,
  male10,
  male11,
]

const female = [
  female0,
  female1,
  female2,
  female3,
  female4,
  female5,
  female6,
  female7,
  female8,
  female9,
  female10,
  female11,
  female12,
]

export const Avatar = {
  MALE: male,
  FEMALE: female,
  UNDEFINED: male,
}
