import EventEmitter from 'eventemitter3';
import { DefaultError } from '../../../error';

export interface IError {
  error: {
    error_code: string;
  };
}

export interface IErrorPanel {
  error: DefaultError;
  emitter: EventEmitter;
}
