/* eslint-disable @typescript-eslint/lines-between-class-members */

const KEYS = {
  'www.koyobear.com': '6f2ded1b-0724-5f60-8e62-70b43008105c',
  'koyo-bear-test-4-2.webflow.io': '75133322-a5ca-507a-b56c-ec94a730acd3',
  'jp.koyobear.com': '3006fdf3-dfd1-577c-8135-83946cffbb01',
};

export class KoyobearHelper {

  static getApiKey() {
    const domain = window.location.hostname;
    return KEYS[domain];
  }

  selectSize(size: string) {
    const found = this.getArticleSizeOptions().find((item) => {
      const sizeEl = item.querySelector('.size');
      const currentSize = sizeEl ? sizeEl.textContent.split('/')[0] : '';
      return currentSize == size;
    });

    return new Promise((fulfill, reject) => {
      if (found) {
        found.click();
        setTimeout(() => fulfill(found), 300);
      } else {
        reject(new Error(`size ${size} option not found`));
      }
    });
  }

  addToCart(button: HTMLElement) {
    if (button && this.isSizeAvailable(button)) {
      const submit = document.querySelector(
        '.add-to-cart-button',
      ) as HTMLElement;
      submit.click();
    } else {
      const size = this.getOptionSize(button);
      const message = `Size ${size} not available`;
      console.error(message);
      alert(message);
    }
  }

  isSizeAvailable(button: HTMLElement) {
    return !button.classList.contains('w--ecommerce-pill-disabled');
  }

  getOptionSize(button: HTMLElement) {
    const sizeEl = button.querySelector('.size');
    return sizeEl.textContent.split('/')[0];
  }

  getArticleSizeOptions(): Array<HTMLElement> {
    return Array.from(document.querySelectorAll('.size-button'));
  }

  getArticleSizeList(): Array<string> {
    return this.getArticleSizeOptions()
      .filter((item: HTMLElement) => this.isSizeAvailable(item))
      .map((item: HTMLElement) => this.getOptionSize(item));
  }
}
