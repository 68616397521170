import React, { useContext } from 'react';
import { Button } from '../Button';
import Arrow from './ArrowIcon';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { TooltipContainer } from './TooltipContainer';
import Events from './Events';
import Processing from './Processing';

const LabelResult = styled.span`
  display: inline-block;
  text-align: center;
  font-weight: bold;
  flex-grow: 2;
  white-space: nowrap;
`;

const SizeBlock = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  & .fpt-sizeblock-value {
    font: normal 14px Inter900
  }

  & .fpt-sizeblock-description {
    font-size: 10px;
  }
`;

const SizeSection = styled.div``;

const MatchingResult = (props) => {
  const { article, emitter, matching, settings } = props;

  if(!matching) {
    return (<Processing />);
  }

  const size = matching.size ? matching.size.value : '-';
  const description = matching.size ? matching.size.description : '';

  const handlePress = () => {
    emitter.emit(Events.onRecommendationPress, {
      article,
      matching
    });
  };

  return (
    <TooltipContainer messageId="drv.matching-result.tooltip.text"
      settings={settings}>
      <Button
        type="button"
        {...props}
        onClick={handlePress}
      >
        <LabelResult>
          <FormattedMessage id="drv.matching-result.message"
            values={{ size: `` }} />
        </LabelResult>
        <SizeBlock>
          <SizeSection className="fpt-sizeblock-value">
            {size}
          </SizeSection>
          <SizeSection className="fpt-sizeblock-description">
            {description}
          </SizeSection>
        </SizeBlock>
        <Arrow />
      </Button>
    </TooltipContainer>
  )
};

export default MatchingResult
