import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { MatchingState } from '../Recommendation/recommendation.interface';
import MatchingResult from './MatchingResult';
import {
  ArticleData,
  Matching,
  ShopSettings,
} from '../../interfaces/ProductService.interface';
import { FontsStyle } from '../FontsStyle';
import Processing from './GradientSpinner';
import { CtaButtonWithTooltip } from './CtaButtonWithTooltip';
import EventEmitter from 'eventemitter3';

interface CtaButtonProps extends React.ComponentPropsWithRef<'button'> {
  emitter: EventEmitter,
  matching: Matching,
  settings: ShopSettings,
  article: ArticleData,
}

const outcome = [ CtaButtonWithTooltip, MatchingResult, MatchingResult, Processing ];

export const CtaPanel: FunctionComponent<CtaButtonProps> = (props) => {

  const { emitter, matching, settings, article } = props;
  const [status, setStatus] = useState(MatchingState.UNDEFINED);

  const OutcomeComponent = outcome[status];

  useEffect(() => {

    if (!settings.reloadEnabled) {
      setStatus(MatchingState.UNDEFINED);
      return;
    }

    if (!matching) {
      setStatus(MatchingState.UNDEFINED);
      return;
    }

    const code = matching.error_code;
    const isKnownError = code == 'MADB1-00' || code == 'MADB2-00' || code == 'BEDB1-00';

    if (code == 'MAST0-00') {
      setStatus(MatchingState.SUCCESS);
    } else if (code == 'pending') {
      setStatus(MatchingState.PROCESSING);
    } else if (isKnownError) {
      setStatus(MatchingState.ERROR);
    } else {
      setStatus(MatchingState.UNDEFINED);
    }

  }, [matching]);

  return (
    <>
      <FontsStyle />
      <OutcomeComponent
        matching={matching}
        emitter={emitter}
        settings={settings}
        article={article}
      />
    </>
  )
}
