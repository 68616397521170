import React, {
  FunctionComponent,
} from 'react';
import styled from 'styled-components';
import { CtaButton } from './CtaButton';
import { TooltipContainer } from './TooltipContainer';

export const CtaButtonWithTooltip: FunctionComponent<any> = (props) => {

  const { article, matching, emitter, settings } = props;

  return (
    <TooltipContainer messageId="drv.cta-button.tooltip.text" settings={settings}>
      <CtaButton article={article} matching={matching} emitter={emitter} />
    </TooltipContainer>
  )
}
