import React from 'react';
import './ErrorComponent.css';
import EventEmitter from 'eventemitter3';
import { ErrorPanel } from './ErrorPanel';

interface PropsWithChildrenNodes {
  children?: React.ReactNode;
  code: string | undefined;
  source: string | undefined;
  hideAction: boolean | false; 
  emitter: EventEmitter;
}
const ErrorComponent = (props: PropsWithChildrenNodes) => {



  const { emitter } = props;

  return (
      <div className="fpt-c-error-page-container">
        <div className="fpt-c-error-page">
          <ErrorPanel code={props?.code} emitter={emitter} source={props?.source} hideAction={props?.hideAction}   showFooter={true} ></ErrorPanel>
        </div>
      </div>
  );
};

export { ErrorComponent };
