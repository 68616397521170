export enum PollingState {
  UNDEFINED,
  MEASURING,
  DONE,
  CANCELLED,
  TIMEOUT
}

export const PollingEvents = {
  onMeasurementDone: 'onMeasurementDone',
  onQRPanelClose: 'onQRPanelClose',
  onTimeout: 'onTimeout',
};
