import AbstractShop from './AbstractShop';
import axios from 'axios';

const KEY_MAP = {
  'fashion-spa.cu2qdtboy0-public1-p13-public.model-t.cc.commerce.ondemand.com': '2589b2fb-2e67-5f73-8ed3-3e78d58d768f',
  'fashion-spa.sfashion-sapphire.usdemo.hybris.com': 'bfa0552b-86ad-5920-8377-ab4c6b7c7156',
};

const SHOE_MAP = {
  '555': 'SAP_DIVERGE',
  '616': 'SAP_ADIDAS_M',
  // '': 'SAP_ADIDAS_W',
  '618': 'SAP_EVERLANE',
  '597': 'SAP_TANNERS',
  '843': 'SAP_DIVERGE',
  '636': 'SAP_BILLY_FOOTWEAR',
};

const ARTICLE_NAME_MAP = {
  '555': 'Diverge Sneakers',
  '616': 'Adidas Sneakers',
  // '': 'SAP_ADIDAS_W',
  '618': 'Everlane Slip-on Sneaker',
  '597': "Tanner's Sneakers",
  '843': 'Allbirds Wool Runners',
  '636': 'Billy Footwear Casual Shoes',
};

function getArticleNumberById(id:string) {
  return SHOE_MAP[id.substr(0, 3)] ? SHOE_MAP[id.substr(0, 3)] : 'SAP_DIVERGE';
}

function getArticleNameById(id:string) {
  return ARTICLE_NAME_MAP[id.substr(0, 3)] ? ARTICLE_NAME_MAP[id.substr(0, 3)] : 'Diverge Sneakers';
}

const productPattern = /product\/(\d+)/;

function getSpartacusArticleId() {
  const pathname = window.location.pathname;
  const match = productPattern.exec(pathname);
  return match ? match[1] : '555001';
}

function getApiKey() {
  const domain = window.location.hostname;
  return KEY_MAP[domain];
}

export class SpartacusService extends AbstractShop {

  constructor() {
    super();
    const articleId = getSpartacusArticleId();
    this.articleId = articleId;
    this.articleNumber = getArticleNumberById(articleId);
    this.articleName = getArticleNameById(articleId);
    this.productSrc = '';
    this.apiKey = getApiKey();
    this.themeName = 'default';
    this.sizes = [];
    this.lang = 'en';
  }

  hasReload() {
    return true;
  }

  getArticleId() {
    const articleId = getSpartacusArticleId();
    this.articleId = articleId;
    this.articleNumber = getArticleNumberById(articleId);
    return this.articleId;
  }

  getArticleNumber() {
    const articleId = getSpartacusArticleId();
    this.articleId = articleId;
    this.articleNumber = getArticleNumberById(articleId);
    return this.articleNumber;
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': getApiKey(),
        'X-SHOP-DOMAIN': window.location.origin,
      },
    });
  }

}
