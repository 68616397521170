import React from 'react';
import { InfoBox } from './ErrorParser';
import { IconInfo, LongArrow } from './assets/images';
import './ErrorInfoBox.css';
import { Trans } from 'react-i18next';

export const enum ToastType {
  message,
  withVideo,
}
interface Props {
  variant: InfoBox;
  message: string;
  isTryToVisible: boolean;
  isLongArrowVisible: boolean;
  videoUrl?: string;
}

function ErrorInfoBox(props?: Props) {
  const CDNurl = `https://cdn.footprinttech.de/assets/videos/${props?.videoUrl}`;

  return (
    <>
      <div className="fpt-c-error-tooltip-container">
        <div className="fpt-c-error-message-container">
          <div className="fpt-c-error-tooltip-info-button-container">
            <img className="fpt-c-error-tooltip-info-button" src={IconInfo} />
          </div>
          <div className="fpt-c-error-tooltip-message-container">
            {props?.isTryToVisible && (
              <div className={`fpt-c-error-tooltip-message`}>
                <b>
                  <Trans i18nKey="cc.error.page-action-try-to"></Trans>
                </b>
              </div>
            )}
            <div className={`fpt-c-error-tooltip-message`}>
              <Trans i18nKey={props?.message} components={[<b />, <br />]} />
            </div>
            {props?.variant == InfoBox.WithVideo && (
              <div className="fpt-c-error-wrappper">
                <video id="tutVideo" autoPlay loop playsInline muted>
                  <source src={CDNurl} type="video/mp4" />
                </video>
              </div>
            )}
          </div>
        </div>
      </div>
      {props?.isLongArrowVisible && (
        <img className="fpt-c-error-tooltip-long-arrow" src={LongArrow} />
      )}
    </>
  );
}

export default ErrorInfoBox;
