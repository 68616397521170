import React from 'react';
import { AvailableOptions } from './AvailableOptions';
import { UnavailableOptions } from './UnavailableOptions';

const options = [AvailableOptions, UnavailableOptions];

export function UserOptions(props: any) {

  const { availability, matching, emitter } = props;

  const OptionsComponent = options[availability];

  return (<OptionsComponent matching={matching} emitter={emitter}></OptionsComponent>);
}
