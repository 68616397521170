import { Availability } from '../components/Recommendation/recommendation.interface';

const checkoutService = {
  getAvailability: function (available: Array<any>, matching: any) : Availability {

    let found = false;

    if (matching && matching.size) {
      found = available.find(item => item == matching.size.value);
    }

    return found ? Availability.AVAILABLE : Availability.UNAVAILABLE;
  },

  formatCurrency: function (
    value: number | undefined, lang = 'de-DE',
    currency = 'EUR') : string {

    const intl = new Intl.NumberFormat(lang,
        { style: 'currency', currency: currency });

    return value ? intl.format(value) : '';
  },
};

export default checkoutService;
