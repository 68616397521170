import UniversalSans600 from 'url:../../assets/fonts/UniversalSans-v1-2-50-100-0-713112212111-01-600.woff'
import UniversalSans900 from 'url:../../assets/fonts/UniversalSans-v1-2-50-100-0-713112212111-01-890.woff'
import SourceSans3Regular from 'url:../../assets/fonts/SourceSans3-Regular.ttf'
import Inter400 from 'url:../../assets/fonts/Inter-Medium.ttf'
import Inter500 from 'url:../../assets/fonts/Inter-Regular.ttf'
import Inter600 from 'url:../../assets/fonts/Inter-SemiBold.ttf'
import Inter700 from 'url:../../assets/fonts/Inter-Bold.ttf'
import Inter900 from 'url:../../assets/fonts/Inter-900.ttf'
import Rubik from 'url:../../assets/fonts/Rubik-Regular.ttf'
import RubikSemiBold from 'url:../../assets/fonts/Rubik-SemiBold.ttf'
import RubikBold from 'url:../../assets/fonts/Rubik-Bold.ttf'
import { createGlobalStyle } from 'styled-components'
export const FontsStyle = createGlobalStyle`
@font-face {
  font-family: "UniversalSans600";
  font-weight: 600;
  font-style: normal;
  src: url(${UniversalSans600}) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "UniversalSans900";
  font-weight: 900;
  font-style: normal;
  src: url(${UniversalSans900}) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SourceSans3";
  font-weight: 400;
  font-style: normal;
  src: url(${SourceSans3Regular}) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Inter400";
  font-weight: 400;
  font-style: normal;
  src: url(${Inter400}) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter500";
  font-weight: 500;
  font-style: normal;
  src: url(${Inter500}) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter600";
  font-weight: 600;
  font-style: normal;
  src: url(${Inter600}) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter700";
  font-weight: 700;
  font-style: normal;
  src: url(${Inter700}) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter900";
  font-weight: 900;
  font-style: normal;
  src: url(${Inter900}) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RubikBold";
  font-weight: 500;
  font-style: bold;
  src: url(${RubikBold}) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Rubik600";
  font-weight: 600;
  font-style: bold;
  src: url(${RubikSemiBold}) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Rubik";
  font-weight: 400;
  font-style: normal;
  src: url(${Rubik}) format("truetype");
  font-display: swap;
}
`;
