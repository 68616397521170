import AbstractShop from './AbstractShop';
import axios from 'axios';

const apiKey = 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF';

export class GenericService extends AbstractShop {

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': apiKey,
      },
    });
  }

}
