import axios, { AxiosInstance } from 'axios';

export interface ICreateSessionData {
  screen_width: number;
  screen_height: number;
  analytics_accepted: boolean;
  client_session_id: string | null;
}

class UserService {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.API_URL,
    });
  }

  setSessionId(sessionId: string) {
    this.client.defaults.headers.common.Authorization = `Bearer ${sessionId}`;
  }

  searchUser(email: string) {
    return this.client.head(`/users/?email=${email}`)
      .then(result => {
        return {
          email,
          status: {
            code: result.status,
            text: result.statusText,
          },
        };
      });
  }

}

export default new UserService();
