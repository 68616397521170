import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from './libraries/languageDetector';

import { Button } from './components/Button/Button';
import { ErrorComponent } from './components/ErrorComponent/ErrorComponent';
import { ErrorPanel } from './components/ErrorComponent/ErrorPanel';
import { Events } from './components/ErrorComponent/Events';
import { Footer } from './components/Footer/Footer';

const languageDetector = new LanguageDetector();
const lang = languageDetector.Detect();
const convertedLang = lang?.toLowerCase().split('-')[0];

const i18nOptions = {
  fallbackLng:
    convertedLang === 'de' ||
    convertedLang === 'en' ||
    convertedLang === 'ja' ||
    convertedLang === 'fr' ||
    convertedLang === 'tr' ||
    convertedLang === 'pl'
      ? convertedLang
      : 'en',
  backend: {
    loadPath: 'https://cdn.footprinttech.de/assets/locales/{{lng}}/messages.json',
  },
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
};

i18n
  .use(HttpBackend)  
  .use(initReactI18next) 
  .init(i18nOptions);

export {
  i18n,
  Button,
  ErrorComponent,
  ErrorPanel,
  Events,
  Footer,
  LanguageDetector,
};
