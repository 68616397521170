export class DefaultError extends Error {
  public errorCode:string;
  public createdAt: Date;
  constructor(errorCode = "GEN01-00", ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DefaultError);
    }

    this.name = "DefaultError";
    // Custom debugging information
    this.errorCode = errorCode;
    this.createdAt = new Date();
  }
}
