import AbstractShop from './AbstractShop';
import axios from 'axios';

export class swtestenvironmentService extends AbstractShop {
  articleName: string;

  articleNumber: string;

  apiKey: string;

  themeName: string = 'default';

  clientSessionId: string;

  sizes: Array<string> = [];

  productSrc: string = '';

  customGv: string = '2-step-child';

  constructor() {
    super();
    this.articleName = 'Test Shoe';
    this.articleNumber = 'WMS_TABLE_FULL';
    this.apiKey = '1351eb90-6281-53d6-be26-25a843556a19';
    this.themeName = 'default';
    this.sizes = [];
  }

  getTarget() {
    return process.env.FPT_APP_URL;
  }

  getUmaTarget() {
    return process.env.FPT_UMA_URL;
  }

  handleMatching() {
    console.log('[driver] handling matching');
    return Promise.resolve();
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': '1351eb90-6281-53d6-be26-25a843556a19',
        'X-SHOP-DOMAIN': window.location.origin,
      },
    });
  }
}
