/* eslint-disable @typescript-eslint/lines-between-class-members */
import { ShopwareHelper } from '../tools/ShopwareHelper';
import { PodolinoHelper } from './PodolinoHelper';
import AbstractShop from './AbstractShop';
import axios from 'axios';

const apiKey = '093b13eb-6e4f-518e-b077-768d1bccc2e1'; // tryon.footprinttech.de

function getArticleSizesFromUI() {
  const result = [];
  const query = 'select.product-detail-configurator-select-input > option';
  const items =  document.querySelectorAll(query);
  items.forEach((item:HTMLElement) => result.push(item.innerText.trim()));
  return result;
}

function getArticleNumber() {
  /*return 'WMS_TABLE_FULL';
  const pat = /^(\d+)\.\d+/;
  const el = document.querySelector(
    '.product-detail-ordernumber-container .product-detail-ordernumber') as HTMLElement;
  return pat.exec(el.innerText)[1];
  */
  return document.querySelector('[itemprop=sku]').innerHTML.split('.')[0].trim();
}

function getArticleName() {
  //const el = document.querySelector('.product-detail-name') as HTMLElement;
  //return el.innerText;
  return document.querySelector('[itemprop=name]').innerHTML.split('|')[0].trim();
}

function addGlobalStyle() {
  const style = document.createElement('style');
  style.innerHTML = `
        :root .btn {
          border-radius: 1rem;
        }
      `;
  document.head.appendChild(style);
}

export class LiveServiceForTryon extends AbstractShop {

  articleId: string;
  shopware: ShopwareHelper;
  helper: PodolinoHelper;

  constructor() {
    super();
    this.apiKey = apiKey;
    this.themeName = process.env.PODOLINO_THEME;
    this.helper = new PodolinoHelper();
    this.articleNumber = getArticleNumber();
    this.articleName = getArticleName();
    this.customGv = '2-step-child';
    this.sizes = getArticleSizesFromUI();
    let meta = document.head.querySelector('meta[property="og:image"]') as HTMLMetaElement;

    if (!meta) {
      meta = document.querySelector('meta[property="og:image"]') as HTMLMetaElement;
    }

    this.productSrc = meta && meta.content ? meta.content : '';
    const footerForm = document.querySelector('.footer-contact-form');

    if (footerForm) {
      footerForm.remove();
    }
    addGlobalStyle();
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': apiKey,
        'X-SHOP-DOMAIN': window.location.origin,
      },
    });
  }

  // hasReload is overridden to disable dynamic CTA button, which displays measurement reusing.
  hasReload() {
    return false;
  }

}
