export const colors = {
  almond: '#fdf5ec',
  azure: '#f8fcfd',
  blackish: '#1a1919',
  'bluey-grey': '#8d99c4',
  'dark-slate-blue': '#1e4e5f',
  'eerie-black': '#242424',
  emerald: '#00da7c',
  golden: '#fabb00',
  grey1: '#F4F2F0',
  grey2: '#322F30',
  grey3: 'rgba(50, 47, 48, 0.125)',
  grey4: '#939190',
  grey5: '#e0dedd',
  grey6: 'rgba(50, 47, 48, 0.2)',
  grey7: '#929090',
  puertorico: '#4CA998',
  red1: '#D8021A',
  'reddish-pink': '#ff345e',
  white: '#FFFFFF',
};

export const Azure = colors.azure;
export const DarkSlateBlue = colors['dark-slate-blue'];
