import React from 'react';
import QRCode from "react-qr-code";
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Header, Text, QRCodeContainer } from './Elements';

interface IQRCodePanel {
  target: URL;
}
const CodePanelContainer = styled.div`
  margin-bottom: 25px;
`;

const QRCodePanel = function(props: IQRCodePanel) {
  const { target } = props;

  return (<>
  <CodePanelContainer>
    <Header>
      <FormattedMessage id="drv.qr-gateway-title" />
    </Header>
    <Text>
      <FormattedMessage id="drv.qr-gateway-message"   values={{
        b: (chunks) => <b>{chunks}</b>,
      }}/>
    </Text>
    <Text>
      <FormattedMessage
        id="drv.qr-gateway-hint"
        values={{
          b: (chunks) => <b>{chunks}</b>,
        }}
      />
    </Text>
    <QRCodeContainer>
      <QRCode value={target.toString()} size={108} />
    </QRCodeContainer>
      </CodePanelContainer>
    </>);
}

export default QRCodePanel;
