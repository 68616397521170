import styled from 'styled-components';

export const RecommendationPanel = styled.div`
  position: relative;
  padding: 27px 0;
  margin: 193px 26px;
  object-fit: contain;
  text-align: center;
  color: var(--dark-slate-blue);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius:20px;
  background-color: #f8fcfd;
  width: 100%;
`;
