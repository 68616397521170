import axios, { AxiosInstance } from 'axios';
import { ShopSettings } from '../interfaces/ProductService.interface';
import { AssetsService, CDN_ASSET_URL } from './AssetsService';

class ShopSettingsService {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.API_URL,
    });
  }

  setApiKey(apiKey: string) {
    this.client.defaults.headers.common['X-API-KEY'] = apiKey;
  }

  async getSettings(): Promise<ShopSettings> {
    console.log(`[driver] ShopSettingsService using ${process.env.API_URL} as API url`);

    let settings:ShopSettings = null;

    const settingsEl = document.querySelector('#fpt-settings');

    if (settingsEl) {
      // Shopware6 settings
      settings = JSON.parse(settingsEl.textContent);
    } else {
      const domain = window.location.hostname;
      const result = await this.client.get(`shops/settings?domain=${domain}`);
      const { data } = result;
      settings = data.length > 0 ? data[0] : { domain: '' };  
    }

    if (settings.theme && settings.theme !== 'default') {
      const theme = await AssetsService.fetchTheme(settings.theme);
      settings.theme = theme;
    } else {
      settings.theme = {};
    }

    const { brandLogo } = settings;

    if (brandLogo) {
      settings.theme.shopLogo = `${CDN_ASSET_URL}/logos/${brandLogo}`;
    }

    return settings;
  }

}

export default new ShopSettingsService();
