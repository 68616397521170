import ShopifyShop from './ShopifyShop';
import { Purchase } from '../interfaces/shopify.interface';
import axios from 'axios';

const API_KEY_MAP = {
  'billyfootwear.com': '97308209-0a99-569c-9c1c-3623028dd44c',
  'sandbox-billy-footwear.myshopify.com': '36b288a6-a9df-52cf-b5f9-587d2a0c2c49',
};

function getVariantSize(variant) {
  return variant.public_title.split('/')[1].trim();
}

function getApiKey() {
  const domain = window.location.hostname;
  return API_KEY_MAP[domain];
}

function getArticleNumberFromSKU(sku:string) {
  const parts = sku.split('-');
  const hasThreeParts = parts.length > 2 && /[a-z]|[A-Z]/.test(parts[2]);
  if (hasThreeParts) {
    return `${parts[0]}-${parts[1]}-${parts[2]}`;
  }
  return `${parts[0]}-${parts[1]}`;
}

export class BillyFootwearService extends ShopifyShop {

  constructor() {
    super();

    const productData = (window as any).meta?.product;
    const variant = productData.variants[0];

    this.articleName = variant.name.split('-')[0].trim();
    this.articleNumber = getArticleNumberFromSKU(variant.sku);
    this.articleId = productData.id;

    this.variants = productData.variants.map((item) => {
      return {
        id: item.id,
        available: true,
        size: getVariantSize(item),
      };
    });

    const meta = document.head.querySelector('meta[property="og:image"]') as HTMLMetaElement;

    this.productSrc = meta?.content;
    this.apiKey = getApiKey();
    this.themeName = 'Billy';
    this.sizes = productData.variants.map(item => getVariantSize(item));
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': getApiKey(),
      },
    });
  }

  static isPurchase() {
    const shopInterface = (window as any).Shopify;
    return (
      window.location.pathname.includes('checkouts') ||
      window.location.pathname.includes('orders')
    ) &&
      shopInterface && shopInterface.checkout &&
      shopInterface.checkout.line_items &&
      shopInterface.checkout.line_items.length > 0;
  }

  static getPurchase(): Purchase {
    const { checkout } = (window as any).Shopify;

    return {
      tax: checkout.total_tax,
      value: checkout.total_price,
      shipping: checkout.shipping_rate.price,
      currency: checkout.currency,
      transaction_id: checkout.order_id,
      items: checkout.line_items.map(item => {
        return {
          id: item.product_id,
          price: item.price,
          quantity: item.quantity,
        };
      }),
    };
  }

}
