import ShopifyShop from './ShopifyShop';
import { IMedia } from '../interfaces/shopify.interface';
import axios from 'axios';

export class PodolinoShopifyService extends ShopifyShop {

  constructor() {
    super();
    const productData = (window as any).product;
    this.articleNumber = 'PFI_TEST'; // '' + productData.id;
    this.articleName = productData.title;
    this.articleId = '' + productData.id;
    const media = productData.media
      .filter((item: IMedia) => item.media_type === 'image')
      .reduce(
        (previous: IMedia, current: IMedia) => {
          if (current.height < previous.height) {
            return current;
          }
          return previous;
        },
        productData.media[0]);
    this.productSrc = media.src;
    this.apiKey = '43750087-71fa-52b4-9d73-4d509ef34982';
    this.themeName = 'default';
    this.variants = productData.variants.map(item => {
      return {
        id: item.id,
        size: item.options[0],
        available: item.available,
      };
    });
    this.sizes = productData.variants
      .filter(item => item.available)
      .map(item => item.options[0]);
    const btn = document.querySelector('#footprinttech-button') as HTMLElement;
    btn.style.marginBottom = '3rem';
    btn.style.maxWidth = '44rem';
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': '43750087-71fa-52b4-9d73-4d509ef34982',
        'X-SHOP-DOMAIN': window.location.origin,
      },
    });
  }

}
