import React from 'react';
import Button from './Button';
import Spinner from './Spinner';

export default function AsyncButton(props:any) {
  const { children, state, onClick } = props;
  const btn = document.querySelector('#footprinttech-button') as HTMLElement;
  const parentClass = btn && btn.className ? btn.className : '';
  const className = state && state === 'LOADING' ?
    'fpt-cta-button fpt-loading' : `fpt-cta-button ${parentClass}`;

  return (
    <Button className={className} type="button" onClick={onClick}>
      <Spinner />
      { children }
    </Button>
  );
}
