import React from 'react';
import { FormattedMessage,useIntl   } from 'react-intl';
import styled from 'styled-components';
import { Images, boxDark } from '../../../assets';
import { Events } from '@footprint/common-components';
import EventEmitter from 'eventemitter3';
import RecommendationEvents from '../../Recommendation/Events';

const HeaderContainer = styled.div`
  color: white;
  display: flex;
  font-size: 1.25rem;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin: 0 30px 0 30px;
`

const Headline = styled.div`
  font-size: 16px;
  font-family: 'Inter700';
  color: #1e4e5f;
  margin-left: 10px
`

const CloseIcon = styled.img``;
const ShareIcon = styled.img``;
const Button = styled.button`
  font-size: 1rem;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
`;
type Props = {
  matching?: any;
  emitter: EventEmitter;
}
const CheckoutHeader: React.FunctionComponent<Props> = (props) => {
  const { emitter, matching} = props
  const intl = useIntl()

  const sizeNumber = matching.size.value;
  const sizeDescription = matching.size.description;
  function extractRootDomain(url) {
      // Extract hostname
      let hostname;
      if (url.indexOf("://") > -1) {
          hostname = url.split('/')[2];
      } else {
          hostname = url.split('/')[0];
      }

      // Remove port number if present
      hostname = hostname.split(':')[0];

      // Remove www if present
      hostname = hostname.replace("www.", "");

      // Split the hostname into parts
      const parts = hostname.split('.').reverse();

      // Check the parts to determine the root domain
      if (parts.length >= 2) {
          // Handle cases like .co.uk, .com.au
          if (parts[1].match(/(com|co|net|org|edu|gov|ac|as|biz|eu|info|mobi|name|pro|aero|museum)$/)) {
              return parts[2] + '.' + parts[1] + '.' + parts[0];
          } else {
              return parts[1] + '.' + parts[0];
          }
      } else if (parts.length === 1) {
          return parts[0];
      }
      return null;
  }


  const shareData = {
    title: intl.formatMessage(
      { id: 'drv.shareLight.title' },
      { domain: extractRootDomain(window.location.href) }
    ),
    text: intl.formatMessage(
      { id: 'drv.shareLight.message' },
      {
        linkToPDP: `${intl.formatMessage({ id: 'drv.shareLight.linkToPDP' })} ${window.location.href}`,
        recommendedSize: sizeNumber + ' ' + sizeDescription
      }
    ),
  };

  const handleShare = async () => {
    emitter.emit(RecommendationEvents.onSharePress);
    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.error('Share failed:', err);
      }
    } else {
      console.warn('Web Share API not supported in this browser');
    }
  };

  const handleClose = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    emitter.emit(Events.onCancelPress, matching);
  }
  return (
    <HeaderContainer>
    <ShareIcon src={Images.shareIcon} onClick={handleShare}/>
      <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
        <img src={boxDark} alt="Box" width={16} />
        <Headline>
          <FormattedMessage id="drv.header.shoebox" />
        </Headline>
      </div>
      <CloseIcon src={Images.close} onClick={handleClose}/>
    </HeaderContainer>
  );
}

export default CheckoutHeader;
