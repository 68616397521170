import React, { useEffect } from 'react';
import { Images} from '../../assets/Images';
import styled from 'styled-components';
import EventEmitter from 'eventemitter3';
import QRCodePanel from './QRCodePanel';
import PollingPanel from './PollingPanel';
import { PollingState, PollingEvents } from './Interface';

interface IQRGatewayProps {
  target: URL,
  emitter: EventEmitter,
  polling: PollingState,
}

const OuterContainer = styled.div`
  width: 633px;
  padding: 25px;
  border-radius: 30px;
  background-color: #f8fcfd;
  margin: auto;
`;

const InnerContainer = styled.div`
  padding-bottom: 10px;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  margin: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  color: var(--dark-slate-blue);
  cursor: pointer;
  width: 46px;
`;

const CloseBut = styled.img``;

const ContentContainer = styled.div`
  padding: 10px;
  text-align: left;
  position: relative;
`;

const outcome = [ QRCodePanel, PollingPanel, PollingPanel ];

const QRGateway = function (props: IQRGatewayProps) {
  const { target, emitter, polling } = props;
  const OutcomeComponent = outcome[polling];

  useEffect(() => {

    console.log('[driver] initializing QRGateway',
      PollingState[polling].toString());

  }, [polling])

  return (
      <OuterContainer>
        <InnerContainer>
          <ContentContainer>
            <CloseButton type="button" onClick={(ev) => {
              ev.nativeEvent.stopImmediatePropagation();
              emitter.emit(PollingEvents.onQRPanelClose);
            }}>
              <CloseBut src={Images.close} width={34} />
            </CloseButton>
            <OutcomeComponent target={target} />
          </ContentContainer>
        </InnerContainer>
      </OuterContainer>
  )
}

export default QRGateway
