import React from 'react'
import styled from 'styled-components'
import { DefaultTheme } from '../../../theme'

interface ButtonProps {
  onClick?: () => void
  variant?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'link-primary'
    | 'link-secondary'
    | 'link-danger'
    | 'wide-primary'
    | 'wide-secondary'
    | 'wide-danger'
}

const Button = styled.button`
  padding: ${DefaultTheme['btn-padding']};
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter500;
  cursor: pointer;
  text-transform: none;
  display: block;
  line-height: 1.15;

  &.primary {
    background-color: ${DefaultTheme['btn-bg-color-primary']};
    border-color: ${DefaultTheme['btn-border-color-primary']};
    color: ${DefaultTheme['btn-color-primary']};
    border-radius: ${DefaultTheme['btn-border-radius-primary']};
    margin: 20px auto;
    white-space: nowrap;
    line-height: 1;

    &:disabled {
      opacity: 0.2;
    }
  }

  &.secondary {
    background-color: ${DefaultTheme['btn-bg-color-secondary']};
    border-color: ${DefaultTheme['dark-slate-blue']};
    color: ${DefaultTheme['btn-color-secondary']};
    border-radius: ${DefaultTheme['btn-border-radius-secondary']};
    line-height: 1;
  }

  &.danger {
    background-color: ${DefaultTheme['btn-bg-color-danger']};
    color: ${DefaultTheme['btn-color-danger']};
    border-radius: ${DefaultTheme['btn-border-radius-primary']};
    margin: ${DefaultTheme['btn-padding']} auto;
    line-height: 1;
  }

  &.link-primary {
    color: ${DefaultTheme['btn-color-link-primary']};
    text-decoration: none;
    padding: 18px;
    font-weight: bold;
    border: none;
    background: none;
    box-shadow: none;
    display: inline-block;
    background-color: ${DefaultTheme['btn-bg-color-link-primary']};
    line-height: 1;
  }

  &.link-secondary {
    background-color: ${DefaultTheme['btn-bg-color-secondary']};
    border: none;
    color: ${DefaultTheme['btn-color-secondary']};
    border-radius: ${DefaultTheme['btn-border-radius-secondary']};
    width: 100%;
    padding-top: ${DefaultTheme['btn-padding-top-bottom']};
    padding-bottom: ${DefaultTheme['btn-padding-top-bottom']};
    transition: all 0.3s;

    &:active {
      filter: brightness(0.8);
      opacity: 0.5;
    }
  }

  &.link-danger {
    color: ${DefaultTheme['btn-color-link-danger']};
    text-decoration: underline;
    border: none;
    background: none;
    box-shadow: none;
    display: inline-block;
    background-color: ${DefaultTheme['btn-bg-color-link-danger']};
  }

  &.wide-primary {
    background-color: ${DefaultTheme['btn-bg-color-primary']};
    border-color: ${DefaultTheme['btn-border-color-primary']};
    color: #fff;
    border-radius: ${DefaultTheme['btn-border-radius-primary']};
    width: 100%;
    padding: ${DefaultTheme['btn-padding']};
    margin: 20px 0;
    transition: all 0.3s;
    border-width: 1px;
    white-space: nowrap;
    line-height: 1;

    &:hover {
      filter: brightness(1.2);
    }

    &:active {
      filter: brightness(0.8);
    }

    &:disabled {
      opacity: 0.2;
    }
  }

  &.wide-secondary {
    background-color: ${DefaultTheme['btn-bg-color-secondary']};
    border-color: ${DefaultTheme['btn-border-color-secondary']};
    color: ${DefaultTheme['btn-color-secondary']};
    border-radius: ${DefaultTheme['btn-border-radius-secondary']};
    width: 100%;
    padding: ${DefaultTheme['btn-padding']};
    margin: 20px 0;
    transition: all 0.3s;
    line-height: 1;

    &:active {
      filter: brightness(0.8);
      opacity: 0.5;
    }
  }

  &.wide-danger {
    background-color: ${DefaultTheme['btn-bg-color-danger']};
    color: ${DefaultTheme['btn-color-danger']};
    border-radius: ${DefaultTheme['btn-border-radius-primary']};
    margin: ${DefaultTheme['btn-padding']} auto;
    width: 100%;
    line-height: 1;
  }

  &.fpr-button--center {
    margin: auto;
  }
`

export default Button
