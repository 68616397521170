import AbstractShop from './AbstractShop';
import axios from 'axios';

const apiKey = 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF';

export class DummyService extends AbstractShop {

  constructor() {
    super();
    this.articleNumber = 'WMS_TABLE_FULL';
    this.articleName = 'Test Shoe';
    this.productSrc = '';
    this.apiKey = apiKey;
    this.themeName = 'default';
    this.sizes = [];
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': apiKey,
        'X-SHOP-DOMAIN': 'http:www.dummy..de',
      },
    });
  }

}
