import React, { useContext } from 'react';
import { ArticleInfo } from '../Common/ArticleInfo';
import styled from 'styled-components'
import { UserOptions } from './UserOptions';
import { RecommendationPanel } from './RecommendationPanel';
import checkoutService from './CheckoutService';
import { CheckoutHeader } from '../Common/CheckoutHeader';
import { ArticleContext, ShopSettingsContext } from '../../FootprintTech/AppController';
import {
  Matching,
} from '../../interfaces/ProductService.interface';
import EventEmitter from 'eventemitter3';

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

interface RecommendationProps {
  emitter: EventEmitter;
  matching: Matching;
}

export function RecommendationComponent(props: RecommendationProps) {

  const {
    matching,
    emitter,
  } = props;

  const shopSettings = useContext(ShopSettingsContext);
  const article = useContext(ArticleContext);

  console.log('[driver] article sizes', article.sizes);
  console.log(`[driver] article sum ${article.sum}`);
  const availability = shopSettings.addToCartEnabled? 0 : 1;
  console.log('[driver] Availability:', availability);

  return (
    <ParentContainer>
      <RecommendationPanel>
        <CheckoutHeader
          matching={matching}
          emitter={emitter} ></CheckoutHeader>
        <ArticleInfo
          articleName={article.name}
          manufacturer={article.manufacturer}
          productSrc={article.image} matching={matching}
          sum={article.sum}
          emitter={emitter}></ArticleInfo>
        <UserOptions
          availability={availability}
          matching={matching}
          emitter={emitter}></UserOptions>
      </RecommendationPanel>
      </ParentContainer>
  );
}
