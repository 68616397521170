import ShopifyShop from './ShopifyShop';
import { Purchase } from '../interfaces/shopify.interface';
import axios from 'axios';

const apiKey = 'ed9701e9-6ab2-5cd1-b887-c3f853689a61';
const skuPattern = /^(\w+\s\d)/;
const sizePattern = /^EU (\d+[.]?\d+?) \/ US (\d+[.]?\d+?)$/;

function getVariantSize(variant) {
  const title = variant.public_title;
  if (!sizePattern.test(title)) {
    return title;
  }
  const match = sizePattern.exec(title);
  const euSize = match[1];
  // const usSize = match[2];
  return euSize;
}

export class VicinityService extends ShopifyShop {

  constructor() {
    super();

    const productData = (window as any).meta?.product;
    const variant = productData.variants[0];
    const { sku } = variant;
    const meta = document.head.querySelector('meta[property="og:image"]') as HTMLMetaElement;

    this.articleName = variant.name.split('-')[0].trim();

    this.articleNumber = sku;
    this.productSrc = meta?.content;
    this.apiKey = apiKey;
    this.price = this.getArticlePriceFromVariant(variant);
    this.articleId = productData.id;

    this.variants = productData.variants.map((item) => {
      return {
        id: item.id,
        available: true,
        size: getVariantSize(item),
      };
    });

    this.sizes = this.variants.map(item => item.size);
    this.lang = 'en';
  }

  hasTooltip() {
    return true;
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': apiKey,
      },
    });
  }

  static isPurchase() {
    return false;
  }

  static getPurchase(): Purchase {
    return null;
  }

}
