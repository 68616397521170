import styled, { keyframes } from 'styled-components';
import { ringSpinner } from '../../assets/Images';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Spinner = styled.img.attrs((props) => ({
  src: ringSpinner,
  alt: 'Loading',
  className: 'fpt-cta-button__spinner'
}))`
  animation: ${spin} 1s infinite linear;
  height: 32px;
  filter: ${props => props.theme.ctaButton.filter};
`;

export default Spinner;
